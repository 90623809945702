<template>
  <section
    id="fourniture_overview"
    class="grid-zone"
  >
    <div id="block_left">
      <figure id="illustration">
        <img
          :src="imgUrl"
          :alt="$t('general.alt-image-produit')"
          @error="imgHasError = true"
        />
        <Badge
          v-if="product.offre_defaut.nouveaute"
          type="new"
        />
        <Badge
          v-if="product.offre_defaut.promotion"
          type="promo"
        />
      </figure>
    </div>

    <div id="block_right">
      <h1
        id="product_title"
        class="s3 bold"
      >
        {{ title }}
      </h1>
      <p
        v-if="product.marque"
        id="marque"
        class="text-regular"
      >
        {{ $t("produit.marque") }}
        {{ /* TODO: Router-link temporaire en attendant les filtres */ }}
        <router-link
          v-if="true"
          class="link underline"
          :to="{
            name: 'search',
            query: { search: product.marque.libelle, tab: 'fournitures', },
          }"
        >
          {{ product.marque.libelle }}
        </router-link>
        <router-link
          v-else
          class="underline"
          :to="{ name: 'home', }"
        >
          {{ product.marque.libelle }}
        </router-link>
      </p>
      <p
        id="ref_ean"
        class="text-regular"
      >
        <span>{{ $t("produit.ref") }} {{ product.reference_interdacta }}</span>
        <span>{{ $t('produit.ean') }} {{ product.ean }}</span>
      </p>
      <p
        id="disponibilite"
        class="text-regular"
      >
        <span
          v-if="product.offre_defaut.code_disponibilite === 1 && product.stock"
        >
          {{ product.stock }} {{ $t("produit.en-stock") }}
        </span>
        <span>
          <span
            class="circle"
            :class="couleurDispo"
          />
          <span :class="`text-${couleurDispo}`">
            {{ libelleDispo }}
          </span>
        </span>

        <!-- TODO: en attente d'infos-->
        <span v-if="product.offre_defaut.code_disponibilite === 1 && !product.stock">
          sous {{ product.delai_dispo || "?" }} jours
        </span>
        <span v-else-if="[6, 97, 98, ].includes(product.offre_defaut.code_disponibilite) && product.stock > 0">
          jusqu'à fin des stocks.
        </span>
      </p>

      <div id="licences">
        <div
          v-if="product.other_options && product.other_options.length > 1"
          id="options"
          class="flex-vcenter"
        >
          <strong class="text-regular">{{ $t("general.couleurs") }}</strong>
          <ul>
            <li
              v-for="option in product.other_options"
              :key="option[0]"
              :class="{ active: option[0] === product.id, }"
            >
              <router-link
                :to="{ params: { id: option[0], }, }"
                class="circle"
                :title="option[2]"
                :style="{ backgroundColor: `#${option[1]}`, }"
              />
            </li>
          </ul>
        </div>
        <div id="choice_add_to_list">
          <p class="text-small italic">
            {{ $t("produit.prix-ttc-titre-indicatif") }}
          </p>
          <div
            v-if="!surconditionnements || surconditionnements.length <= 1"
            id="no_conditionnement"
          >
            <div class="prices text-medium">
              <strong
                v-if="product.offre_defaut.prix_editeur !== product.offre_defaut.prix_ht"
                class="ttc all-taxes"
                v-html="$t('prix.ttc', { prix: moneyFilter(product.offre_defaut.prix_editeur), })"
              />
              <span
                class="ht duty-free"
                v-html="$t('prix.ht', { prix: moneyFilter(product.offre_defaut.prix_ht), })"
              />
            </div>
            <InputQuantity
              v-model="chosenQuantity"
              :disabled="!computedIsProductAvailable"
              :min="1"
            />
          </div>
          <div
            v-else
            id="conditionnement"
          >
            <div class="flex-vcenter">
              <div class="prices text-medium">
                <p
                  v-if="selectedSurconditionnement.offre.prix_editeur !== selectedSurconditionnement.offre.prix_ht"
                  class="ttc all-taxes"
                >
                  <!-- {{ $t("produit.a-partir-de") }} -->
                  <strong
                    v-html="$t('prix.ttc', { prix: moneyFilter(selectedSurconditionnement.offre.prix_editeur), })"
                  />
                </p>
                <p
                  class="ht duty-free"
                  v-html="$t('prix.ht', { prix: moneyFilter(selectedSurconditionnement.offre.prix_ht), })"
                />
              </div>
              <SelectClassic
                v-model="selectedSurconditionnement"
                :options="surconditionnements"
                :use-placeholder="false"
                status="required"
                :label="$t('expedition.conditionnement')"
                @select="redirectToOffer($event)"
              />
            </div>
            <div id="choices_recap">
              <div>
                {{ /* Réactiver un jour le tableau dégressif + css (#choices_recap > div:first-child) */ }}
                <button
                  v-if="false"
                  id="toggle_tab_degressif"
                  class="button text-regular"
                  :class="{ underline: !showTable, }"
                  data-bs-toggle="collapse"
                  data-bs-target="#tab_degressif"
                  @click="showTable = !showTable"
                >
                  <UilTimesSquare
                    v-if="showTable"
                    size="16"
                  />
                  <span>{{ $t("produit.tableau-degressif") }}</span>
                </button>
                <div
                  id="prices_unity"
                  class="flex-vcenter text-medium"
                >
                  <InputQuantity
                    v-model="chosenQuantity"
                    :disabled="!computedIsProductAvailable"
                    :min="1"
                  />
                  <span>{{ $t("produit.par-unite") }}</span>
                  <strong
                    v-if="selectedSurconditionnement.offre.prix_editeur !== selectedSurconditionnement.offre.prix_ht"
                    class="ttc all-taxes"
                    v-html="$t(
                      'prix.ttc', {
                        prix: moneyFilter(
                          selectedSurconditionnement.offre.prix_editeur / selectedSurconditionnement.quantiteLot
                        ),
                      }
                    )"
                  />
                  <span
                    class="ht"
                    v-html="$t('prix.ht', {
                      prix: moneyFilter(
                        selectedSurconditionnement.offre.prix_ht / selectedSurconditionnement.quantiteLot),
                    }
                    )"
                  />
                </div>
              </div>
            </div>
            <!-- TODO: valeurs ? + à remettre plus tard + trad -->
            <div
              id="tab_degressif"
              class="collapse"
            >
              <table>
                <tr>
                  <th>{{ $t("general.quantite") }}</th>
                  <td>2 à 20</td>
                  <td>20 à 40</td>
                  <td>40 à 60</td>
                  <td> 60 à 80</td>
                  <td>80 à 100</td>
                </tr>
                <tr>
                  <th>{{ $t("produit.prix-unitaire") }}</th>
                  <td>6.66&nbsp;€ HT</td>
                  <td>6.66&nbsp;€ HT</td>
                  <td>6.66&nbsp;€ HT</td>
                  <td>6.66&nbsp;€ HT</td>
                  <td>6.66&nbsp;€ HT</td>
                </tr>
                <tr>
                  <th>{{ $t("general.economie") }}</th>
                  <td>-</td>
                  <td>11%</td>
                  <td>22%</td>
                  <td>44%</td>
                  <td>66%</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="computedIsProductAvailable"
        id="zone_select_button"
      >
        <p class="text-medium bold">
          {{ $t("general.ajouter-produit") }}
        </p>
        <div class="zone-liste-panier">
          <ButtonClassic
            variant="special"
            :label="$t('liste.a-une-liste')"
            color="primary"
            icon="left"
            size="medium"
            :disabled="addToListDisabled || isImpersonating || !chosenQuantity || isFetchingActiveLists"
            @click="!addToListDisabled ?
              $emit('add-to-list', { id: product.offre_defaut.id, quantite: chosenQuantity, })
              : ''
            "
          >
            <template #left-icon>
              <InfiniteLoader v-if="isFetchingActiveLists" />
              <UilBookmark v-else />
            </template>
          </ButtonClassic>
          <ButtonClassic
            variant="solid"
            :label="$t('panier.au-panier')"
            color="secondary"
            icon="right"
            size="large"
            :disabled="addToListDisabled || isImpersonating || !chosenQuantity"
            @click="!addToListDisabled ?
              $store.dispatch(
                'addOffersToPanier',
                { lignes: [{ id_offre: product.offre_defaut.id, quantite: chosenQuantity, }, ], })
              : ''
            "
          >
            <template #right-icon>
              <IconPanier />
            </template>
          </ButtonClassic>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  ButtonClassic,
  InputQuantity,
  SelectClassic,
  moneyFilter,
  InfiniteLoader,
} from "@lde/core_lde_vue";
import Badge from "@/components/Badge.vue";

import { UilTimesSquare, UilBookmark } from "@iconscout/vue-unicons";
import IconPanier from "@/components/icons/IconPanier.vue";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { mapGetters } from "vuex";

/**
 * Affiche les informations de base du produit avec les licences disponibles.
 */
export default {
  name: "FournitureOverview",
  components: {
    ButtonClassic,
    InputQuantity,
    SelectClassic,
    Badge,
    UilTimesSquare,
    UilBookmark,
    IconPanier,
    InfiniteLoader,
  },
  mixins: [mixinDisponibilite],
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton d'enregistrement.
     * @param {Object} offre Offre associée à la quantité.
     */
    "add-to-list",
  ],
  data() {
    return {
      showTable: false,
      chosenQuantity: 1,
      imgHasError: false,
      selectedSurconditionnement: null,
    };
  },
  computed: {
    ...mapGetters(["hasPerm", "isGE", "isFetchingActiveLists"]),
    title() {
      return this.product.libelle;
    },
    imgUrl() {
      if (this.imgHasError) {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        return require("@lde/core_lde_vue/dist/assets/media/missing_img/no_img_fourniture.svg");
      }

      return `https://www.interdacta.fr/photos-1/300x300/${this.product.reference_interdacta}.jpg`;
    },
    addToListDisabled() {
      return !this.hasPerm("add_lignedeliste")
        || (this.product.offre_defaut && this.product.offre_defaut.prescripteur)
        || !this.computedIsProductAvailable;
    },
    surconditionnements() {
      const sc = this.product.surconditionnements.map((s) => ({
        id: s.fourniture_lot.offre_defaut.id,
        label: this.$t("produit.lot-de-x", { qte: s.quantite }),
        value: s.fourniture_lot.id,
        offre: s.fourniture_lot.offre_defaut,
        quantiteLot: s.quantite,
        quantite: 1, // Init pour l'InputQuantity
        unite: s.fourniture_unite?.id,
      }));

      let currentSc = sc.find((s) => s.offre.id === this.product.offre_defaut.id);

      const unit = {
        id: this.product.offre_defaut.id,
        label: this.$t("produit.a-l-unite"),
        value: currentSc?.unite || this.product.id,
        offre: this.product.offre_defaut,
        quantiteLot: 1,
        quantite: 1, // Init pour l'inputQuantity
      };

      if (!currentSc) {
        currentSc = unit;
      }

      if (sc.length && this.selectedSurconditionnement === null) {
        this.$set(this, "selectedSurconditionnement", currentSc);
      }

      return [unit, ...sc];
    },
  },
  methods: {
    moneyFilter,
    /**
     * Redirige vers l'offre selon le surconditionnement choisi.
     * @param {Object} selectedSc Surconditionnement choisi.
     */
    redirectToOffer(selectedSc) {
      if (parseInt(this.$route.params.id, 10) !== parseInt(selectedSc.value, 10)) {
        this.$router.push({ params: { id: selectedSc.value } });
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/fournitures/fournitures_overview.scss";
</style>
